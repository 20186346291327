import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'image-content',
  templateUrl: './image-content.component.html',
  styleUrls: ['./image-content.component.scss']
})
export class ImageContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
