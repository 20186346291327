<dg-modal
  #modal
  size="md"
  title="Endereço"
  (afterClose)="clear()"
>
  <div modal-body [formGroup]="form">
    <div class="row">
      <div class="col-12">
        <label class="form-label">Estado</label>
        <input class="form-control form-control-sm" formControlName="estado">
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="form-label">Cidade</label>
        <input class="form-control form-control-sm" formControlName="cidade">
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="form-label">Cep</label>
        <input class="form-control form-control-sm" formControlName="cep">
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="form-label">Bairro</label>
        <input class="form-control form-control-sm" formControlName="bairro">
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="form-label">Rua</label>
        <input class="form-control form-control-sm" formControlName="rua">
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="form-label">Numero</label>
        <input class="form-control form-control-sm" formControlName="numero">
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="form-label">Complemento</label>
        <input class="form-control form-control-sm" formControlName="complemento">
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="form-label">Ponto de referência</label>
        <input class="form-control form-control-sm" formControlName="pontoReferencia">
      </div>
    </div>
  </div>
  <div modal-footer>
    <button (click)="close()" class="btn btn-secondary">Voltar</button>
    <button (click)="save()" class="btn btn-success">Salvar</button>
  </div>
</dg-modal>
