<div
  class="card food1-card-produto {{config.props.cardProdutoMenu.size.toLowerCase()}}"
  routerLink="produto/{{produto.id}}"
>
  <div class="row g-0">
    <div
      class="content-image"
      [class.order-1]="config.props.cardProdutoMenu.direction == 'END'"
      [class.order-0]="config.props.cardProdutoMenu.direction == 'START'"
      [class.col-3]="config.props.cardProdutoMenu.size != 'LG'"
      [class.col-4]="config.props.cardProdutoMenu.size == 'LG'"
    >
      <img
        [src]="produto?.imageUrl || '/assets/img/default-image.jpg'"
        class="img-fluid"
        alt="..."
        [class.rounded]="config.props.produto.img.format != 'ROUND'"
        [class.circle]="config.props.produto.img.format == 'ROUND'"
      >
    </div>
    <div
      class="d-flex flex-column"
      [class.order-0]="config.props.cardProdutoMenu.direction == 'END'"
      [class.order-1]="config.props.cardProdutoMenu.direction == 'START'"
      [class.col-9]="config.props.cardProdutoMenu.size != 'LG'"
      [class.col-8]="config.props.cardProdutoMenu.size == 'LG'"
    >
      <div class="card-body">
        <h5 class="card-title">{{produto?.nome || '--'}}</h5>
        <p class="card-text">{{produto?.descricao}}</p>
      </div>
      <div class="card-footer mt-auto">
        <p class="card-text"><small class="text-muted">R$ {{produto.valor}}</small></p>
      </div>
    </div>
  </div>
</div>
