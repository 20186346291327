import { Injectable } from '@angular/core';

/**
 * service para lidar com modificações no layout, possívelmente fique obsoleto quando
 * novas implementações de layouts forem adicionadas. Então será necessário modificar
 * para lidar com dados diversos e/ou mapear todas as modificações dos layouts
 */

@Injectable({
  providedIn: 'root'
})
export class ConfigDesignLayoutService {

  modoEditor: boolean = false;

  props: {
    produto: {
      img: {
        format: 'SQUARE' | 'ROUND',
      }
    },
    cardProdutoMenu: {
      direction: 'START' | 'END',
      size: 'SM' | 'MD' | 'LG',
      elementosPorLinha: '1' | '2'
    },
    cardProdutoCarrinho: {
      direction: 'START' | 'END',
      size: 'SM' | 'MD' | 'LG',
    }
  } = {
    cardProdutoMenu: {
      direction: 'START',
      size: 'LG',
      elementosPorLinha: '2'
    },
    cardProdutoCarrinho: {
      direction: 'START',
      size: 'MD',
    },
    produto: {
      img: {
        format: 'SQUARE',
      }
    },
  };

  constructor() {
    if (window.sessionStorage.getItem('modo-editor')) {
      this.modoEditor = Boolean(window.sessionStorage.getItem('modo-editor'));
    }
  }

  atualizarSessionStoragy() {
    window.sessionStorage.setItem('props-design', JSON.stringify(this.props))
  }

  getSessionProps() {
    const props = window.sessionStorage.getItem('props-design');

    if (!!props) {
      this.props = {
        ...this.props,
        ...JSON.parse(props)
      };
    }
  }

  changeModoEditor(status?: boolean) {
    if (status != undefined) {
      this.modoEditor = status;
    } else {
      this.modoEditor = !this.modoEditor;
    }

    if (this.modoEditor) {
      window.sessionStorage.setItem('modo-editor', 'true')
    } else {
      window.sessionStorage.removeItem('modo-editor')
    }
  }
}
