<div class="container">
  <div class="row">
    <div class="col-12">
      <table class="w-100">
        <thead>
          <tr>
            <th>nome</th>
            <th>link</th>
            <th>tamplate</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of listEmpresas" (dblclick)="redirectEmpresa(item)">
            <td>{{item.nome}}</td>
            <td>{{item.link}}</td>
            <td>{{item.template}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
