import {Injectable} from "@angular/core";
import {VendaEntity} from "../../entitys/venda.entity";
import {PedidoProdutoEntity} from "../../entitys/pedido-produto.entity";

/**
 * Service para ligar com o carrinho de compras
 */

@Injectable({
  providedIn: 'root'
})
export class CarrinhoService {

  values: VendaEntity = new VendaEntity()

  constructor() {
  }

  addProduto(produto: PedidoProdutoEntity) {
    this.values.produtos.push(produto);
    let newTotal = 0;
    this.values.produtos.map(prod => {
      newTotal = newTotal + (Number(prod.valor) * (prod.quantidade || 1))
    });
    this.values.total = (newTotal)
    this.saveSession()
  }

  saveSession() {
    window.sessionStorage.setItem('carrinho', JSON.stringify(this.values))
  }

  issetValues() {
    const carrinho = window.sessionStorage.getItem('carrinho')
    if (!!carrinho) {
      this.values = JSON.parse(carrinho)
    }
  }

  updateQuantidadeProduto(indexPedidoProduto: number, quantidade: number) {
    if (!this.values.produtos[indexPedidoProduto]) return;

    this.values.produtos[indexPedidoProduto].quantidade = quantidade;
    this.values.produtos[indexPedidoProduto].valor = quantidade * Number(this.values.produtos[indexPedidoProduto].produto?.valor);
    this.updateTotalCarrinho()
  }

  updateTotalCarrinho() {
    this.values.total = this.values.produtos
      .reduce((acumulador, pedidoProduto) => acumulador + Number(pedidoProduto.valor), 0)
    this.saveSession()
  }

  removeProduto(index: number) {
    if (!this.values.produtos[index]) return;
    this.values.produtos.splice(index, 1);
    this.updateTotalCarrinho()
  }
}

