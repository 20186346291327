<layout-food1>
  <div id="basic-page-cardapio-produtos"  class="container-fluid">
    <div class="row mb-2 mt-2">
<!--      <div class="col-4">-->
<!--        <label class="form-label">Direção da imagem</label>-->
<!--        <select [(ngModel)]="config.props.produto.img.direction" changeDetectPropsDesign class="form-select form-select-sm">-->
<!--          <option value="START">Direita</option>-->
<!--          <option value="END">Esquerda</option>-->
<!--        </select>-->
<!--      </div>-->
<!--      <div class="col-4">-->
<!--        <label class="form-label">Formato da imagem</label>-->
<!--        <select [(ngModel)]="config.props.produto.img.format" changeDetectPropsDesign class="form-select form-select-sm">-->
<!--          <option value="ROUND">Circular</option>-->
<!--          <option value="SQUARE">Quadrada</option>-->
<!--        </select>-->
<!--      </div>-->
<!--      <div class="col-4">-->
<!--        <label class="form-label">tamanho da imagem</label>-->
<!--        <select [(ngModel)]="config.props.cardProdutoMenu.size" changeDetectPropsDesign class="form-select form-select-sm">-->
<!--          <option value="LG">Grande</option>-->
<!--          <option value="MD">Média</option>-->
<!--          <option value="SM">Pequena</option>-->
<!--        </select>-->
<!--      </div>-->
    </div>

    <div class="row">
      <div class="col-12" style="padding: 0 1.7rem">
        <content-config-design-layout title="Abrir configurações do menu">
          <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12">
              <label class="form-label">Direção da imagem</label>
              <select [(ngModel)]="config.props.cardProdutoMenu.direction" changeDetectPropsDesign class="form-select form-select-sm">
                <option value="START">Direita</option>
                <option value="END">Esquerda</option>
              </select>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12">
              <label class="form-label">Formato da imagem</label>
              <select [(ngModel)]="config.props.produto.img.format" changeDetectPropsDesign class="form-select form-select-sm">
                <option value="ROUND">Circular</option>
                <option value="SQUARE">Quadrada</option>
              </select>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12">
              <label class="form-label">Tamanho do card</label>
              <select [(ngModel)]="config.props.cardProdutoMenu.size" changeDetectPropsDesign class="form-select form-select-sm">
                <option value="LG">Grande</option>
                <option value="MD">Média</option>
                <option value="SM">Pequena</option>
              </select>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12">
              <label class="form-label">Produtos por linha</label>
              <select [(ngModel)]="config.props.cardProdutoMenu.elementosPorLinha" changeDetectPropsDesign class="form-select form-select-sm">
                <option value="1">1</option>
                <option value="2">2</option>
              </select>
            </div>
          </div>
        </content-config-design-layout>
      </div>
    </div>
    <div class="row">
      <div *ngFor="let item of listProdutos" class="{{getColClass()}}">
        <food1-card-produto-menu1
          style="min-height: 100%"
          [produto]="item"
        ></food1-card-produto-menu1>
      </div>
    </div>
  </div>
</layout-food1>
