<!--<div class="container-fluid">-->
<!--  <content-config-design-layout title="Configurações globais">-->
<!--    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12">-->
<!--      <label class="form-label">Formato da imagem</label>-->
<!--      <select [(ngModel)]="config.props.produto.img.format" changeDetectPropsDesign class="form-select form-select-sm">-->
<!--        <option value="ROUND">Circular</option>-->
<!--        <option value="SQUARE">Quadrada</option>-->
<!--      </select>-->
<!--    </div>-->
<!--  </content-config-design-layout>-->
<!--</div>-->

<div id="layout-banner">
  <div class="banner">
    <img src="/assets/img/default-image.jpg" class="img-fluid" alt="...">
  </div>
</div>


<div class="container-fluid">
  <content-config-design-layout title="Abrir configurações do banner">
    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12">
      <label class="form-label">Formato da imagem</label>
      <select [(ngModel)]="config.props.produto.img.format" changeDetectPropsDesign class="form-select form-select-sm">
        <option value="ROUND">Circular</option>
        <option value="SQUARE">Quadrada</option>
      </select>
    </div>
  </content-config-design-layout>

  <div class="row">
    <div class="col-12 d-inline-flex justify-content-end">
      <span class="text-muted" (click)="config.changeModoEditor()">
        {{config.modoEditor ? 'Desabilitar' : 'Habilitar'}} modo editor
      </span>
    </div>
  </div>

  <div class="row">
    <div class="col-12 d-inline-flex justify-content-start">
      <span class="text-muted">
        {{!!session.expediente ? 'Aberto' : 'Fechado'}}
      </span>
    </div>
  </div>
</div>

<div class="container-fluid mt-3 pb-5 mb-5" style="padding: 0 1em">
  <div class="row">
    <div class="{{showCarrinho ? 'col-xl-9 col-lg-8 col-md-8' : 'col-12'}} col-sm-12">
      <div *ngIf="showMenu" class="container-fluid">
        <content-config-design-layout title="Opções menu">
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12">
            <label class="form-label">Exibir Todas as categorias</label>
            <select [(ngModel)]="config.props.produto.img.format" changeDetectPropsDesign class="form-select form-select-sm">
              <option value="ROUND">Circular</option>
              <option value="SQUARE">Quadrada</option>
            </select>
          </div>
        </content-config-design-layout>
      </div>
      <div *ngIf="showMenu">
        <ul class="list-group list-group-horizontal">
          <li class="list-group-item" [routerLink]="session.baseRouter+'categotias'">Todas as categorias</li>
          <li class="list-group-item" [routerLink]="session.baseRouter">todos os produtos</li>
          <li
            *ngFor="let item of listCategorias" class="list-group-item"
            [routerLink]="session.baseRouter" [queryParams]="{ categoria: item.id }"
          >{{item.nome}}</li>
        </ul>
      </div>

      <ng-content></ng-content>
    </div>
    <div *ngIf="showCarrinho" class="col-xl-3 col-lg-4 col-md-4 col-sm-12">
      <div class="container-fluid">
        <content-config-design-layout title="Opções">
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12">
            <label class="form-label">Formato da imagem</label>
            <select [(ngModel)]="config.props.produto.img.format" changeDetectPropsDesign class="form-select form-select-sm">
              <option value="ROUND">Circular</option>
              <option value="SQUARE">Quadrada</option>
            </select>
          </div>
        </content-config-design-layout>
      </div>
      <card-side-carrinho></card-side-carrinho>
    </div>
  </div>
</div>
