import {Component, Input, OnInit} from '@angular/core';
import {ProdutoEntity} from "../../../../../entitys/produto.entity";
import {
  ConfigDesignLayoutService
} from "../../../../../services/config-design-layout-service/config-design-layout.service";
import {PedidoProdutoEntity} from "../../../../../entitys/pedido-produto.entity";
import {ProdutoService} from "../../../../../services/produto-service/produto.service";

@Component({
  selector: 'food1-produtos1',
  templateUrl: './food1-produtos1.component.html',
  styleUrls: ['./food1-produtos1.component.scss', './../food1-produto-base-style.scss']
})
export class Food1Produtos1Component implements OnInit {

  @Input() pedidoProduto!: PedidoProdutoEntity;

  constructor(public config: ConfigDesignLayoutService, public produtoService: ProdutoService) { }

  ngOnInit(): void {
  }

}
