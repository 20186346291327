

export const ROOT_ESTABELECIMENTO = 'empresa/rootEmpresa/$nomeEstabelecimento$';
export const EXPEDIENTE = 'empresa/rootEmpresa/$nomeEstabelecimento$';

export const PRODUTOS = 'v1/food/produto/public'

export const VENDAS = 'v1/food/vendas'
export const VENDAS_PEDIDO = 'v1/food/esteira-pedido/pedido'
export const VENDAS_VERIFY_EXISTE_PEDIDO_PENDENTE = 'v1/food/vendas/$id$/pedidoPendente'

export const CATEGORIA = 'v1/food/categorias/public'

export const CLIENTE_VERIFY_TEL = 'v1/auth/login/client/telefone'

export const CARRINHO = 'v1/food/esteira-pedido/carrinho'


export const ENDERECO = 'v1/cliente-endereco'
export const ENDERECO_ULTIMO_USADO = 'v1/cliente-endereco/ultimo-endereco'
