import {Component, Input, OnInit} from '@angular/core';
import {ClienteEnderecoEntity} from "../../../../entitys/cliente-endereco.entity";
import {flip} from "@popperjs/core";

@Component({
  selector: 'card-endereco',
  templateUrl: './card-endereco.component.html',
  styleUrls: ['./card-endereco.component.scss']
})
export class CardEnderecoComponent implements OnInit {

  @Input('endereco') endereco: ClienteEnderecoEntity | undefined;
  @Input('active') active: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
