<div class="container-fluid mt-2">
  <div class="row">
    <div class="col-3">
      <img
        [src]="pedidoProduto?.produto?.imageUrl || '/assets/img/default-image.jpg'"
        class="img-fluid rounded-start"
        alt="..."
      >
    </div>
    <div class="col-9">
      <h4>{{pedidoProduto?.nome || '--'}}</h4>
      <p>R$: {{pedidoProduto?.valor || '--'}}</p>
      <p class="card-text">{{pedidoProduto?.produto?.descricao}}</p>
    </div>
  </div>

  <div class="row mt-2" *ngIf="!!pedidoProduto">
    <div class="col-12">
      <div class="accordion">
        <div class="accordion-item" *ngFor="let itemComplemento of (pedidoProduto.complementos || []); let indexComplemento = index">
          <h2 class="accordion-header" [attr.id]="'id-complementos-button-'+ indexComplemento">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#id-complementos-content-'+ indexComplemento"
              aria-expanded="true"
              [attr.aria-controls]="'id-complementos-content-'+ indexComplemento"
            >
              {{itemComplemento.nome}}
              {{itemComplemento?.complemento?.obrigatorio ? ' - Obrigatório' : ''}}
            </button>
          </h2>
          <div
            [attr.id]="'id-complementos-content-'+ indexComplemento"
            class="accordion-collapse collapse show"
            [attr.aria-labelledby]="'id-complementos-button-'+ indexComplemento"
          >
            <div class="accordion-body" style="padding: .5rem">
              <ul class="list-group list-produto-comlementos" style="background: yellow">
                <li
                  *ngFor="let item of (itemComplemento?.items || []); let indexItem = index"
                  class="list-group-item d-inline-flex justify-content-between"
                >
                  <div>
                    <input [type]="produtoService.getTypeInputIten(itemComplemento)" [checked]="realInput.checked" (click)="realInput.click()">
                    <input #realInput hidden type="checkbox" (ngModelChange)="produtoService.verifySelectInputClicked(itemComplemento, indexItem)" [(ngModel)]="item.selected">
                    {{item?.nome}}
                  </div>

                  <div class="input-group mb-3" style="width: 120px">
                    <span class="input-group-text">-</span>
                    <input type="number" class="form-control" value="0">
                    <span class="input-group-text">+</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
