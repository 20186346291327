<layout-food1>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <ol class="list-group list-group-numbered">
          <li
            *ngFor="let item of listCategorias"
            class="list-group-item d-flex justify-content-between align-items-start"
            [routerLink]="session.baseRouter"
            [queryParams]="{categoria: item.id}"
          >
            <div class="ms-2 me-auto">
              <div class="fw-bold">{{item.nome}}</div>
              Cras justo odio
            </div>
            <span *ngIf="!!item?.quantidadeProdutos" class="badge bg-primary rounded-pill">{{item?.quantidadeProdutos}}</span>
          </li>
        </ol>
      </div>
    </div>
  </div>
</layout-food1>

