import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {SessionService} from "../../../services/session-service/session.service";
import {InitLayoutService} from "../../../services/init-layout-service/init-layout.service";

@Injectable()
export class AddEmpresaHeaderInterceptor implements HttpInterceptor {

  constructor(private session: SessionService, private init: InitLayoutService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.get('removeAllHeaders')) {
      // Remove todos os headers
      request = request.clone({
        headers: request.headers.delete('removeAllHeaders')
      });
    } else if (!!this.session.rootEmpresa?.id && !request.headers.get('noAddHeaders')) {
      // Adiciona o header 'empresaid' se aplicável
      request = request.clone({
        setHeaders: {empresaid: String(this.session.rootEmpresa.id)},
      });
    }

    return next.handle(request);
  }
}
