<dg-modal #modal title="Login" size="sm">
  <div modal-body>
    <div class="row">
      <form [formGroup]="form">
        <div class="col-12">
          <label class="form-label">Telefone</label>
          <input formControlName="telefone" name="telefone" class="form-control" mask="(00) 00000-0000" placeholder="(00) 00000-0000">
        </div>
        <div class="col-12">
          <label class="form-label">Nome</label>
          <input formControlName="nome" class="form-control">
        </div>
      </form>
      <div class="col-12">
        <button (click)="save()" class="btn btn-success">Salvar</button>
      </div>
    </div>
  </div>
</dg-modal>
