import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { SharedLoadEmpresaComponent } from './shared-load-empresa/shared-load-empresa.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";



@NgModule({
  declarations: [
    HomeComponent,
    SharedLoadEmpresaComponent
  ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule
    ]
})
export class SharedPagesModule { }
