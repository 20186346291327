import { Injectable } from '@angular/core';
import {AbstractTokenService} from "@datagrupo/dg-ng";
import {HttpClient} from "@angular/common/http";

export interface sshToken {
  sub: string,
  name: string,
  perfil: string
}

@Injectable({
  providedIn: 'root'
})
export class TokenService extends AbstractTokenService<sshToken>{

  path_refreshToken: string = '';
  override name_localStoragy_token = 'no-token-exist'

  constructor(public http: HttpClient) {
    super(http)
    /**
     * adiciona ao nome do localstogary do token o id da empresa.
     * isso é feita para gerar um nome unico
     */
    const rootEmpresaString: string | object | null = window.sessionStorage.getItem('rootEmpresa')
    if (!!rootEmpresaString) {
      const rootEmpresa = JSON.parse(rootEmpresaString)
      this.name_localStoragy_token = 'accessToken-'+rootEmpresa.id
    }
  }

}
