import {Component, OnInit} from '@angular/core';
import {HttpService} from "../../../../services/http-service/http.service";
import {PRODUTOS} from "../../../../core/config/endpoints";
import {ActivatedRoute} from "@angular/router";
import {
  ConfigDesignLayoutService
} from "../../../../services/config-design-layout-service/config-design-layout.service";

@Component({
  selector: 'app-food1-home',
  templateUrl: './food1-home.component.html',
  styleUrls: ['./food1-home.component.scss']
})
export class Food1HomeComponent implements OnInit {

  public listProdutos: any[] = [];

  // config = {
  //   cardProduct: {
  //     imgDirection: 'START',
  //     imgFormat: 'SQUARE',
  //     imgSize: 'LG'
  //   }
  // }

  constructor(
    private http: HttpService,
    private actRoute: ActivatedRoute,
    public config: ConfigDesignLayoutService
  ) {
    this.findProd()
    actRoute.queryParams.subscribe(param => {
      // if (param['categoria']) {
      this.findProd(param['categoria'] || '')
      // }
    });
  }

  ngOnInit(): void {
  }

  findProd(categoria?: number | string) {
    const params: { [key: string]: string | number } = {};
    if (categoria) params['categoria'] = categoria;

    this.http.get(PRODUTOS, {params}).subscribe(resp => {
      this.listProdutos = resp.data || [];
    });
  }

  getColClass(): string {
    switch (this.config.props.cardProdutoMenu.elementosPorLinha) {
      case '1':
        return 'col-12';
      case '2':
        return 'col-xl-6 col-lg-6 col-md-6 col-sm-12';
      default:
        return 'col-xl-6 col-lg-6 col-md-6 col-sm-12';
    }
  }
}
