<div
  class="card food1-card-produto food1-card-produto-carrinho {{config.props.cardProdutoCarrinho.size.toLowerCase()}}"
>
  <div class="row g-0">
    <div
      class="content-image col"
      [class.order-2]="config.props.cardProdutoCarrinho.direction == 'END'"
      [class.order-0]="config.props.cardProdutoCarrinho.direction == 'START'"
    >
      <img
        [src]="pedidoProduto.produto?.imageUrl || '/assets/img/default-image.jpg'"
        class="img-fluid"
        alt="..."
        [class.rounded]="config.props.produto.img.format != 'ROUND'"
        [class.circle]="config.props.produto.img.format == 'ROUND'"
      >
    </div>
    <div
      class="content-body d-flex flex-column order-1"
      [class.col-8]="config.props.cardProdutoCarrinho.size != 'LG'"
      [class.col-7]="config.props.cardProdutoCarrinho.size == 'LG'"
    >
      <div class="card-body">
        <h5 class="card-title">{{pedidoProduto.produto?.nome || '--'}}</h5>
        <p class="card-text">{{pedidoProduto.produto?.descricao}}</p>
      </div>
      <div class="card-footer mt-auto">
        <p class="card-text"><small class="text-muted">R$ {{pedidoProduto.valor}}</small></p>
      </div>
    </div>
    <div
      class="content-actions col-2 d-flex justify-content-center align-items-center"
      [class.order-0]="config.props.cardProdutoCarrinho.direction == 'END'"
      [class.order-2]="config.props.cardProdutoCarrinho.direction == 'START'"
    >
      <div class="input-group mb-3">
        <span (click)="changeQuantidadeProduto(pedidoProduto.quantidade-1)" class="input-group-text">-</span>
        <input type="number" class="form-control" [(ngModel)]="pedidoProduto.quantidade">
        <span (click)="changeQuantidadeProduto(pedidoProduto.quantidade+1)" class="input-group-text">+</span>
      </div>
      <button class="btn btn-sm btn-danger m-1">
        X
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <ul class="list-group list-complementos" style="padding: 0 .5rem">
        <li
          *ngFor="let itemComplemento of (pedidoProduto.complementos || [])"
          class="list-group-item"
        >
          <div class="row">
            <div class="col-12">
              {{itemComplemento.nome}}
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <ul class="list-group">
                <li
                  *ngFor="let itemItem of (itemComplemento.items || [])"
                  class="list-group-item"
                >
                  {{itemItem.nome}}
                </li>

              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

