import { Injectable } from '@angular/core';
import {ProdutoComplementosEntity} from "../../entitys/produto-complemento.entity";
import {PedidoProdutoComplementoEntity} from "../../entitys/pedido-produto-complemento.entity";
import {PRODUTOS} from "../../core/config/endpoints";
import {ProdutoEntity} from "../../entitys/produto.entity";
import {PedidoProdutoEntity} from "../../entitys/pedido-produto.entity";
import {PedidoProdutoComplementoItemEntity} from "../../entitys/pedido-produto-complemento-item.entity";
import {HttpService} from "../http-service/http.service";

/**
 * Como todos os layouts precisam lidar com produtos, criei esse service oara centralizar
 * as operações.
 */

@Injectable({
  providedIn: 'root'
})
export class ProdutoService {

  constructor(private http: HttpService) { }

  getTypeInputIten(complemento: PedidoProdutoComplementoEntity) {
    return complemento.complemento?.tipoSelecao == 'MULTIPLO' ? 'checkbox' : 'radio'
  }

  verifySelectInputClicked(complemento: PedidoProdutoComplementoEntity, index: number) {
    if (complemento.complemento?.tipoSelecao == 'UNITARIO') {
      complemento.items.map((item, indexArraay) => {
        if (indexArraay == index) return
        item.selected = false;
      })
    }
  }

  getById(id: string, callback: (data:PedidoProdutoEntity) => void) {
    this.http.get(PRODUTOS + '/' + id).subscribe((resp: { data: ProdutoEntity }) => {
      if (resp.data) {
        const rootProduto = resp.data;
        const pedidoProduto = new PedidoProdutoEntity()
        pedidoProduto.produto = rootProduto;
        pedidoProduto.valor = rootProduto.valor;
        pedidoProduto.nome = rootProduto.nome;
        pedidoProduto.quantidade = 1;
        pedidoProduto.complementos = rootProduto.complementos.map(itemComp => {
          const complemento = new PedidoProdutoComplementoEntity();
          complemento.complemento = itemComp
          complemento.nome = itemComp.nome;
          complemento.quantidade = 0;

          complemento.items = itemComp.items.map(rootItem => {
            const item = new PedidoProdutoComplementoItemEntity()
            item.selected = false;
            item.nome = rootItem.nome;
            item.item = rootItem;

            return item;
          });

          return complemento;
        })

        callback(pedidoProduto);
      }
    })
  }
}
