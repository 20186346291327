import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {DgModalComponent} from "@datagrupo/dg-ng-util";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpHelpersService} from "../../../../../services/http-helpers/http-helpers.service";
import {HttpService} from "../../../../../services/http-service/http.service";
import {ENDERECO} from "../../../../../core/config/endpoints";
import {Subscription} from "rxjs";
import {HttpRequest} from "@angular/common/http";

@Component({
  selector: 'modal-endereco',
  templateUrl: './modal-endereco.component.html',
  styleUrls: ['./modal-endereco.component.scss']
})
export class ModalEnderecoComponent implements OnInit {

  @ViewChild('modal') modal!: DgModalComponent;

  @Output('afterSave') afterSave = new EventEmitter()

  subscriptionCep: Subscription | undefined;

  form = new FormGroup({
    id: new FormControl(''),
    estado: new FormControl('', [Validators.required]),
    cidade: new FormControl('', [Validators.required]),
    cep: new FormControl('', [Validators.required]),
    bairro: new FormControl('', [Validators.required]),
    rua: new FormControl('', [Validators.required]),
    numero: new FormControl('', [Validators.required]),
    complemento: new FormControl(''),
    pontoReferencia: new FormControl(''),
  })

  constructor(
    private httpHelpers: HttpHelpersService,
    private http: HttpService
  ) {}

  ngOnInit(): void {
  }

  open(data?: any) {
    this.form.patchValue(data);
    this.modal.open()
    this.subscriptionCep = this.form.controls['cep'].valueChanges.subscribe(val =>
      this.httpHelpers.getCep(val, resp => {
        this.form.patchValue({
          estado: resp.uf,
          cidade: resp.localidade,
          bairro: resp.bairro,
          rua: resp.logradouro
        })
      }),
    )
  }

  close() {
    this.modal.close()
  }

  clear() {
    this.form.reset('')
    this.subscriptionCep = undefined;
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return
    }

    const data = this.form.value

    let httpRequest = this.http.put(ENDERECO, data);

    if(!data?.id) {
      delete data.id
      httpRequest = this.http.post(ENDERECO, data)
    }

    httpRequest.subscribe(resp => {
      this.close();
      this.afterSave.emit();
    })
  }
}
