import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdatePropsDesignDirective } from './update-props-design.directive';



@NgModule({
  declarations: [
    UpdatePropsDesignDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [UpdatePropsDesignDirective]
})
export class UpdatePropsDesignModule { }
