<div class="card card-cart">
  <div *ngIf="!!session.user.id" class="card-header">
    <ul class="list-group">
      <li class="list-group-item">Olá {{session?.user?.nome}}</li>
    </ul>
  </div>
  <div class="card-body">
    <ul class="list-group">
      <li *ngFor="let item of carrinho.values.produtos" class="list-group-item">{{item?.nome}}</li>
      <li class="list-group-item">Total: {{carrinho.values.total}}</li>
    </ul>
  </div>
  <div class="card-body">
    <button [disabled]="carrinho.values.produtos.length < 1" (click)="link_carrinho()" class="btn btn-success">Ver carrinho</button>
    <button class="btn btn-secondary" (click)="session.logout()">Sair</button>
  </div>
  <div class="card-footer">
    <span>Histórico de pedidos</span>
  </div>
</div>
