import { Component, OnInit } from '@angular/core';
import {rootEmpresa, SessionService} from "../../services/session-service/session.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpService} from "../../services/http-service/http.service";
import {InitLayoutService} from "../../services/init-layout-service/init-layout.service";

@Component({
  selector: 'app-shared-load-empresa',
  templateUrl: './shared-load-empresa.component.html',
  styleUrls: ['./shared-load-empresa.component.scss']
})
export class SharedLoadEmpresaComponent implements OnInit {

  constructor(
    private session: SessionService,
    private route: ActivatedRoute,
    private service: HttpService,
    private templateService: InitLayoutService
  ) {
    this.templateService.findTemplate(route.snapshot.params['nomeEmpresa'])
  }

  ngOnInit(): void {
  }

  // verifyEtabelecimento() {
  //   if (this.session.issetAttr('sisgend-list-estabelecimentos')) {
  //     const listLayoutsEstabelecimentos = <rootEmpresa[]>this.session.getAttr('sisgend-list-estabelecimentos');
  //
  //     const estabelecimento = listLayoutsEstabelecimentos.find(
  //       item => item.nome === this.route.snapshot.params['nomeEmpresa']
  //     );
  //
  //     if (estabelecimento) {
  //       this.session.saveAttr(estabelecimento, 'rootEmpresa');
  //       window.location.reload()
  //     }
  //
  //   } else {
  //     this.buscarEmpresa();
  //   }
  // }

  // buscarEmpresa() {
  //   // alert(this.route.snapshot.params['nomeEmpresa'])
  //   this.service.get('empresa').subscribe(resp => {
  //     this.session.saveAttr(resp, 'sisgend-list-estabelecimentos')
  //     this.verifyEtabelecimento();
  //   })
  //   // if (this.session.issetAttr('sisgend-list-estabelecimentos')) {
  //   //   const listLayoutsEstabelecimentos = this.session.getAttr('sisgend-list-estabelecimentos');
  //   // }
  // }

}
