import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";

interface retornoViaCep {
  logradouro: string,
  bairro: string,
  cidade: string,
  uf: string,
  localidade: string,
  erro: boolean
}

@Injectable({
  providedIn: 'root'
})
export class HttpHelpersService {

  constructor(private http: HttpClient) { }

  getCep(cep: string, callBackSuccess: (d: retornoViaCep) => void, callBackError?: Function) {
    cep = (cep || '').replace('-', '')
    if (cep.length < 8) return;

    return this.http.get<retornoViaCep>(
      `https://viacep.com.br/ws/${cep}/json/`,
      { headers: {removeAllHeaders: 'true'}
      }).subscribe(
      resp => {
        if (resp.erro) {
          if (!!callBackError) {
            callBackError(true);
            return;
          }
        }

        callBackSuccess(resp)
      },
    )
  }
}
