<layout-food1 [showCarrinho]="false">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-xl-9 col-lg-9 col-md-8 col-sm-12">
        <mat-stepper [linear]="true" #stepper>
          <mat-step [completed]="!!session.user.id">
            <ng-template matStepLabel>Seu pedido</ng-template>
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <ul class="list-group">
                          <li
                            *ngFor="let itemProduto of (carrinho.values.produtos || []); let indexProduto = index"
                            class="list-group-item"
                          >
                            <food1-produtos-carrinho1 [pedidoProduto]="itemProduto" [indexPedidoProduto]="indexProduto">
                            </food1-produtos-carrinho1>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    Total: {{carrinho.values.total}}
                  </div>
                </div>
              </div>
            </div>
          </mat-step>

          <mat-step [completed]="!!carrinho?.values?.endereco">
            <ng-template matStepLabel>Endereço</ng-template>
            <div class="card">
              <div class="card-body">
                <div class="row mb-1" *ngIf="!!carrinho?.values?.endereco">
                  <div class="col-12">
                    <card-endereco
                      [endereco]="carrinho.values.endereco"
                      [active]="true"
                    ></card-endereco>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <div class="card pointer" (click)="modal.open()">
                      <div class="card-body">
                        <h6 class="card-title">Novo endereço</h6>
                        <span class="card-text">Adicionar novo endereço</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="card pointer" (click)="modalSelecionarEndereco.open()">
                      <div class="card-body">
                        <h6 class="card-title">Usar outro endereço</h6>
                        <span class="card-text">Veja os endereços cadastrados</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button matStepperNext>Next</button>
              <button matStepperPrevious>back</button>
            </div>
          </mat-step>

          <mat-step [completed]="!!formaPagamentoSelecionada.value">
            <ng-template matStepLabel>Forma de pagamento</ng-template>
            <div>
              <div *ngIf="listFormasPagamento.includes('DINHEIRO')" class="card mb-3" style="height: 80px;">
                <div class="row g-0">
                  <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex justify-content-center align-items-center">
                    <input [formControl]="formaPagamentoSelecionada" name="forma-pagamento" class="form-check" type="radio" value="DINHEIRO">
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                    <img style="max-height: 70px" src="/assets/img/formas-pagamento/dinheiro.png" class="img-fluid rounded-start" alt="Pix">
                  </div>
                  <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                    <div class="card-body">
                      <h5 class="card-title">Dinheiro</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="listFormasPagamento.includes('PIX')" class="card mb-3" style="height: 80px;">
                <div class="row g-0">
                  <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex justify-content-center align-items-center">
                    <input [formControl]="formaPagamentoSelecionada" name="forma-pagamento" class="form-check" type="radio" value="PIX">
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                    <img style="max-height: 70px" src="/assets/img/formas-pagamento/pix.png" class="img-fluid rounded-start" alt="Pix">
                  </div>
                  <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                    <div class="card-body">
                      <h5 class="card-title">Pix</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="listFormasPagamento.includes('CARTAO')" class="card mb-3" style="height: 80px;">
                <div class="row g-0">
                  <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex justify-content-center align-items-center">
                    <input [formControl]="formaPagamentoSelecionada" name="forma-pagamento" class="form-check" type="radio" value="CARTAO">
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                    <img style="max-height: 70px" src="/assets/img/formas-pagamento/cartoes.png" class="img-fluid rounded-start" alt="Pix">
                  </div>
                  <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                    <div class="card-body">
                      <h5 class="card-title">Debido/Crédito</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>Revisar pedido</ng-template>
            <div>
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <ul class="list-group">
                            <li
                              *ngFor="let itemProduto of (carrinho.values.produtos || []); let indexProduto = index"
                              class="list-group-item"
                            >
                              <food1-produtos-carrinho1 [pedidoProduto]="itemProduto" [indexPedidoProduto]="indexProduto">
                              </food1-produtos-carrinho1>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row mb-1" *ngIf="!!carrinho?.values?.endereco">
                        <div class="col-12">
                          <card-endereco
                            [endereco]="carrinho.values.endereco"
                            [active]="true"
                          ></card-endereco>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div *ngIf="formaPagamentoSelecionada.value == 'DINHEIRO'" class="card mb-3" style="height: 80px;">
                        <div class="row g-0">
                          <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex justify-content-center align-items-center">
                            <input disabled [checked]="formaPagamentoSelecionada.value == 'DINHEIRO'" class="form-check" type="radio">
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-3">
                            <img style="max-height: 70px" src="/assets/img/formas-pagamento/dinheiro.png" class="img-fluid rounded-start" alt="Pix">
                          </div>
                          <div class="col-xl-8 col-lg-8 col-md-8 col-sm-7">
                            <div class="card-body">
                              <h5 class="card-title">Dinheiro</h5>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="formaPagamentoSelecionada.value == 'PIX'" class="card mb-3" style="height: 80px;">
                        <div class="row g-0">
                          <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex justify-content-center align-items-center">
                            <input disabled [checked]="formaPagamentoSelecionada.value == 'PIX'" class="form-check" type="radio">
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                            <img style="max-height: 70px" src="/assets/img/formas-pagamento/pix.png" class="img-fluid rounded-start" alt="Pix">
                          </div>
                          <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                            <div class="card-body">
                              <h5 class="card-title">Pix</h5>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="formaPagamentoSelecionada.value == 'CARTAO'" class="card mb-3" style="height: 80px;">
                        <div class="row g-0">
                          <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex justify-content-center align-items-center">
                            <input disabled [checked]="formaPagamentoSelecionada.value == 'CARTAO'" class="form-check" type="radio">
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                            <img style="max-height: 70px" src="/assets/img/formas-pagamento/cartoes.png" class="img-fluid rounded-start" alt="Pix">
                          </div>
                          <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                            <div class="card-body">
                              <h5 class="card-title">Debido/Crédito</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-5 pt-5">
                <div class="col-12 d-inline-flex justify-content-center mt-5 pt-5">
                  <button [disabled]="!session.expediente" (click)="finalizar()" class="btn btn-success mt-5">Finalizar compra</button>
                </div>
              </div>
            </div>
          </mat-step>
        </mat-stepper>
      </div>


      <div hidden class="col-5">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <ul class="list-group">
                  <li
                    *ngFor="let itemProduto of (carrinho.values.produtos || []); let indexProduto = index"
                    class="list-group-item"
                  >
                    <food1-produtos-carrinho1 [pedidoProduto]="itemProduto" [indexPedidoProduto]="indexProduto">
                    </food1-produtos-carrinho1>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-footer">
            Total: {{carrinho.values.total}}
          </div>
        </div>
      </div>
    </div>
  </div>
</layout-food1>


<div class="footer" *ngIf="stepper.selectedIndex != 3">
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <button class="btn btn-info" (click)="next()">Avançar</button>
      </div>
    </div>
  </div>
</div>

<modal-endereco #modal></modal-endereco>

<modal-selecionar-endereco #modalSelecionarEndereco (afterSave)="carrinho.values['endereco'] = $event"></modal-selecionar-endereco>

<modal-login #modalLogin (afterSave)="afterLogin()"></modal-login>
