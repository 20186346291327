import {Component, Input, OnInit} from '@angular/core';
import {HttpService} from "../../../../services/http-service/http.service";
import {CATEGORIA} from "../../../../core/config/endpoints";
import {SessionService} from "../../../../services/session-service/session.service";
import {Router} from "@angular/router";
import {
  ConfigDesignLayoutService
} from "../../../../services/config-design-layout-service/config-design-layout.service";

@Component({
  selector: 'layout-food1',
  templateUrl: './layout-food1.component.html',
  styleUrls: ['./layout-food1.component.scss']
})
export class LayoutFood1Component implements OnInit {

  listCategorias: any[] = [];
  @Input() showCarrinho: boolean = true;
  @Input() showMenu: boolean = true;

  constructor(
    private http: HttpService,
    public session: SessionService,
    private router: Router,
    public config: ConfigDesignLayoutService
  ) {
    http.get(CATEGORIA).subscribe(resp => {
      this.listCategorias = resp.data;
    })
    http.get('empresa/expediente').subscribe(resp => {
      this.session.setExpediente(resp.data)
    })

    // if (session.user.id) {
    //   http.get(replaceEndpointParams(VENDAS_VERIFY_EXISTE_PEDIDO_PENDENTE, session.user.id))
    //     .subscribe(resp => {
    //
    //     })
    // }
  }

  ngOnInit(): void {
  }

  carrinho() {
    this.router.navigate([this.session.baseRouter, 'carrinho']).then()
  }
}
