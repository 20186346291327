import {Directive, HostListener} from '@angular/core';
import {ConfigDesignLayoutService} from "../../services/config-design-layout-service/config-design-layout.service";

@Directive({
  selector: '[changeDetectPropsDesign]'
})
export class UpdatePropsDesignDirective {

  constructor(private designProps: ConfigDesignLayoutService) { }

  @HostListener('change')
  change() {
    this.designProps.atualizarSessionStoragy();
  }
}
