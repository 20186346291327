import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {DgModalComponent} from "@datagrupo/dg-ng-util";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpService} from "../../../services/http-service/http.service";
import {Router} from "@angular/router";
import {SessionService} from "../../../services/session-service/session.service";
import {TokenService} from "../../../services/token-service/token.service";
import {CLIENTE_VERIFY_TEL} from "../../../core/config/endpoints";
import Swal from "sweetalert2";

@Component({
  selector: 'modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.scss']
})
export class ModalLoginComponent implements OnInit {

  @ViewChild('modal') modal!: DgModalComponent;

  @Output('afterSave') afterSave = new EventEmitter()

  form = new FormGroup({
    telefone: new FormControl('', [Validators.required]),
    nome: new FormControl('', [Validators.required]),
  })

  constructor(
    private http: HttpService,
    private router: Router,
    private session: SessionService,
    private token: TokenService
  ) {
    this.form.controls['telefone'].valueChanges.subscribe(val => {
      if (val.length == 11) {
        http.get(CLIENTE_VERIFY_TEL, { params: { telefone: val } }).subscribe(resp => {
          if (resp.data) {
            const tokenAberto = <any>token.abrirToken(resp.data);
            Swal.fire({
              icon: 'question',
              title: tokenAberto.name + '?',
              text: 'Esse é você?'
            }).then(conf => {
              if(conf.isConfirmed) {
                this.token.saveToken(resp.data)
                this.session.setUser();
                this.close()
                this.afterSave.emit()
              }
            })
          }
        })
      }
    })
  }

  ngOnInit(): void {
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const form = this.form.value;

    this.http.post('v1/auth/signup-cliente', form).subscribe(resp => {
      this.token.saveToken(resp.data)
      this.session.setUser();
      this.close()
      this.afterSave.emit()
    })
  }

  open() {
    this.modal.open()
  }

  close() {
    this.modal.close()
  }

  clear() {
    this.form.reset('')
  }

}
