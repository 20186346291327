import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {RouterModule} from "@angular/router";
import {InitLayoutService} from "./services/init-layout-service/init-layout.service";
import {SharedPagesModule} from "./shared-pages/shared-pages.module";
import {SessionService} from "./services/session-service/session.service";
import {AddEmpresaHeaderInterceptor} from "./core/interceptors/add-empresa-header/add-empresa-header.interceptor";
import {TemplatesModule} from "./templates/templates.module";
import {IConfig, NgxMaskModule} from "ngx-mask";
import {CarrinhoService} from "./services/carrinho-service/carrinho.service";
import {ConfigDesignLayoutService} from "./services/config-design-layout-service/config-design-layout.service";
import {AddTokenInterceptor} from "./core/interceptors/add-token/add-token.interceptor";


// config para não impor validação em mascaras NGX-MASK
const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    SharedPagesModule,
    TemplatesModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  providers: [
    InitLayoutService, SessionService, CarrinhoService, ConfigDesignLayoutService,
    { provide: HTTP_INTERCEPTORS, useClass: AddEmpresaHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AddTokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
