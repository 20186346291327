import {Component, Input, OnInit} from '@angular/core';
import {ConfigDesignLayoutService} from "../../../services/config-design-layout-service/config-design-layout.service";

@Component({
  selector: 'content-config-design-layout',
  templateUrl: './content-config-design-layout.component.html',
  styleUrls: ['./content-config-design-layout.component.scss']
})
export class ContentConfigDesignLayoutComponent implements OnInit {

  @Input() title: string = 'Abrir configurações'

  showInputs: boolean = false;

  constructor(public designProps: ConfigDesignLayoutService) { }

  ngOnInit(): void {
  }

}
