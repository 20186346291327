<dg-modal
  #modal
  size="md"
  title="Endereço"
  (afterClose)="clear()"
>
  <div modal-body>
    <div class="row mb-1" *ngFor="let endereco of listEnderecos">
      <div class="col-12">
        <card-endereco
          [endereco]="endereco"
          [active]="enderecoSelecionado?.id == endereco?.id"
          (click)="selecionarEndereco(endereco)"
          (dblclick)="selecionarEndereco(endereco, true)"
        ></card-endereco>
      </div>
    </div>
  </div>
  <div modal-footer>
    <button (click)="close()" class="btn btn-secondary">Voltar</button>
    <button (click)="save()" class="btn btn-success">Salvar</button>
  </div>
</dg-modal>
