import {Component, Input, OnInit} from '@angular/core';
import {ProdutoEntity} from "../../../../../entitys/produto.entity";
import {
  ConfigDesignLayoutService
} from "../../../../../services/config-design-layout-service/config-design-layout.service";

@Component({
  selector: 'food1-card-produto-menu1',
  templateUrl: './food1-card-produto-menu1.component.html',
  styleUrls: ['./food1-card-produto-menu1.component.scss', './../food1-produto-base-style.scss']
})
export class Food1CardProdutoMenu1Component implements OnInit {

  @Input() produto!: ProdutoEntity;

  constructor(public config: ConfigDesignLayoutService) { }

  ngOnInit(): void {
  }

}
