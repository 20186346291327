<layout-food1 [showCarrinho]="false" [showMenu]="false">
  <div class="container mt-4 mb-5 pb-5">
    <div class="row">
      <div class="col-12">
        <ul class="list-group list-group-horizontal">
          <li class="list-group-item" [routerLink]="session.baseRouter">Fazer um novo pedido</li>
          <li class="list-group-item" [routerLink]="session.baseRouter+'historico'">Histórico de pedidos</li>
        </ul>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-3">
        <div class="card">
          <div class="card-header">
            Acompanhamento de pedido
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item" [class.disabled]="venda.status != 'EM_ANALISE'">Em analise</li>
              <li class="list-group-item" [class.disabled]="venda.status != 'PRODUCAO'">Em preparação</li>
              <li class="list-group-item" [class.disabled]="venda.status != 'CAMINHO'">A caminho</li>
              <li class="list-group-item" [class.disabled]="venda.status != 'ENTREGUE'">Entregue</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-9">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Pedido - status: {{venda.status}}</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="accordion">
                  <div class="accordion-item" *ngFor="let itemProduto of (venda.produtos || []); let indexProduto = index">
                    <h2 class="accordion-header" [attr.id]="'id-complementos-button-'+ indexProduto">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#id-complementos-content-'+ indexProduto"
                        aria-expanded="true"
                        [attr.aria-controls]="'id-complementos-content-'+ indexProduto"
                      >
                        {{itemProduto.nome}}
                      </button>
                    </h2>
                    <div [attr.id]="'id-complementos-content-'+ indexProduto" class="accordion-collapse collapse show" [attr.aria-labelledby]="'id-complementos-button-'+ indexProduto">
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-12">
                            {{itemProduto.nome}}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <ul *ngIf="itemProduto.complementos.length > 0" class="list-group">
                              <li *ngFor="let itemComplemento of (itemProduto.complementos || [])" class="list-group-item">
                                <div class="row">
                                  <div class="col-12">
                                    {{itemComplemento.nome}}
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-12">
                                    <ul *ngIf="itemComplemento.items.length > 0" class="list-group">
                                      <li *ngFor="let item of (itemComplemento?.items || [])" class="list-group-item">
                                        {{item?.nome}}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</layout-food1>
