import { Component, OnInit } from '@angular/core';
import {HttpService} from "../../../../services/http-service/http.service";
import {CATEGORIA} from "../../../../core/config/endpoints";
import {SessionService} from "../../../../services/session-service/session.service";

@Component({
  selector: 'app-food1-categorias-main',
  templateUrl: './food1-categorias-main.component.html',
  styleUrls: ['./food1-categorias-main.component.scss']
})
export class Food1CategoriasMainComponent implements OnInit {

  listCategorias: any[] = [];

  constructor(private http: HttpService, public session: SessionService) {
    http.get(CATEGORIA).subscribe(resp => {
      this.listCategorias = resp.data;
    })
  }

  ngOnInit(): void {
  }

}
