<layout-food1>
  <food1-produtos1 *ngIf="pedidoProduto" [pedidoProduto]="pedidoProduto"></food1-produtos1>
</layout-food1>

<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <button [disabled]="verifyDisabledButtonSave()" (click)="finalizar()" class="btn btn-info">Adicionar produto</button>
      </div>
    </div>
  </div>
</div>
