import {NgModule} from '@angular/core';
import {Router, RouterModule, Routes} from '@angular/router';
import {SharedLoadEmpresaComponent} from "./shared-pages/shared-load-empresa/shared-load-empresa.component";
import {LayoutGuard} from "./core/guards/layout.guard";
import {InitLayoutService} from "./services/init-layout-service/init-layout.service";
import {environment} from "../environments/environment";
import {HomeComponent} from "./shared-pages/home/home.component";

/**
 * Função para observar o production no environment e determinar se apresenta
 * as rotar de desenvolvimento ou de produção
 */
function routersSelect(): Routes {
  if (environment.production) {
    return [
      {path: 'client/:nomeEmpresa', component: SharedLoadEmpresaComponent},
      {path: '', component: SharedLoadEmpresaComponent},
    ];
  }

  return [
    {path: 'client/:nomeEmpresa', component: SharedLoadEmpresaComponent},
    {path: '', component: HomeComponent},
  ];
}

const routes: Routes = routersSelect();

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router, private templateService: InitLayoutService) {

    const newRouters = templateService.verifySetRouters()

    if (!!newRouters) {
      this.router.resetConfig([
        newRouters
      ]);
    }
  }
}
