import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalLoginComponent } from './modal-login/modal-login.component';
import {DgModalModule} from "@datagrupo/dg-ng-util";
import {ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";



@NgModule({
  declarations: [
    ModalLoginComponent
  ],
  exports: [
    ModalLoginComponent
  ],
  imports: [
    CommonModule,
    DgModalModule,
    ReactiveFormsModule,
    NgxMaskModule
  ]
})
export class ModalsModule { }
