import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SessionService} from "../../../../services/session-service/session.service";
import {ProdutoService} from "../../../../services/produto-service/produto.service";
import Swal from "sweetalert2";
import {PedidoProdutoEntity} from "../../../../entitys/pedido-produto.entity";
import {CarrinhoService} from "../../../../services/carrinho-service/carrinho.service";

@Component({
  selector: 'app-food1-produto',
  templateUrl: './food1-produto.component.html',
  styleUrls: ['./food1-produto.component.scss']
})
export class Food1ProdutoComponent implements OnInit {

  pedidoProduto: PedidoProdutoEntity | undefined = undefined;

  constructor(
    private actRouter: ActivatedRoute,
    private router: Router,
    private session: SessionService,
    private carrinho: CarrinhoService,
    public produtoService: ProdutoService
  ) {
    if (actRouter.snapshot.params['id']) {
      this.produtoService.getById(actRouter.snapshot.params['id'], prod => this.pedidoProduto = prod)
    }
  }

  ngOnInit(): void {
  }

  verifyDisabledButtonSave(): boolean {
    if (this.pedidoProduto?.complementos.length == 0) return false;

    const result = this.pedidoProduto?.complementos.filter(complemento => {
      if (!!complemento?.complemento?.obrigatorio) {
        return complemento.items.filter(item => item.selected).length == 0
      }
      return false;
    }) || []

    return result.length > 0;
  }

  finalizar() {
    if (this.verifyDisabledButtonSave()) {
      Swal.fire({
        icon: 'warning',
        title: "selecione os itens obrigatórios"
      }).then()
      return;
    }
    if (!this.pedidoProduto) return;

    const produto: PedidoProdutoEntity = JSON.parse(JSON.stringify(this.pedidoProduto));
    produto['complementos'] = produto?.complementos.filter(complemento => {
      complemento.items = complemento.items.filter(item => item.selected)
      return complemento.items.length > 0
    })

    this.carrinho.addProduto(produto);
    this.router.navigate([this.session.baseRouter]).then()
  }
}
