import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Food1Produtos1Component } from './food1-produtos1/food1-produtos1.component';
import {RouterModule} from "@angular/router";
import { Food1ProdutosCarrinho1Component } from './food1-produtos-carrinho1/food1-produtos-carrinho1.component';
import {FormsModule} from "@angular/forms";
import { Food1CardProdutoMenu1Component } from './food1-card-produto-menu1/food1-card-produto-menu1.component';



@NgModule({
  declarations: [
    Food1Produtos1Component,
    Food1ProdutosCarrinho1Component,
    Food1CardProdutoMenu1Component
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule
  ],
  exports: [Food1Produtos1Component, Food1ProdutosCarrinho1Component, Food1CardProdutoMenu1Component]
})
export class Food1ProdutosModule { }
